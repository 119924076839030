import "./RoofTypes.scss";
import { Leftarrowicon } from "../../components/icons/Leftarrowicon";
import { Closeicon } from "../../components/icons/Closeicon";
import { useDispatch, useSelector } from "react-redux";
import { setShow } from "../../store/Show/action";
import {
  getRoofInfo,
  getRoofTypes,
  setRoofInfo,
} from "../../store/Roof/action";
import { useNavigate } from "react-router";
import {
  TooltipComponentBack,
  TooltipComponentForward,
} from "../TooltipComponent/TooltipComponent";
import { TooltipComponentClose } from "../TooltipComponent/TooltipComponent";
import { setFinalAddress } from "../../store/Address/action";
import { setOrderRoofType, setRoofInformation } from "../../store/Order/action";
import { useState } from "react";
import { useEffect } from "react";

const RoofTypes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roofTypes = useSelector((state) => state.Roof.roofTypes);
  const checkedAddress = useSelector((state) => state.Address.checkedAddress);
  const partnerId = useSelector((state) => state.Order.partnerId);
  const [newRoofTypes, setNewRoofTypes] = useState();
  const [roofId, setRoofId] = useState(0);
  const [checkeAddress, setCheckeAddress] = useState();
  const [newLatlng, setNewLatlng] = useState();
  const roofName = window.sessionStorage.getItem("roofName");
  const roofid = JSON.parse(window.sessionStorage.getItem("roofID"));
  const latlng = JSON.parse(window.sessionStorage.getItem("latlng"));
  useEffect(() => {
    dispatch(getRoofTypes());
    const chAddress = JSON.parse(
      window.sessionStorage.getItem("checkedAddress")
    );
    if (roofid !== null) {
      setRoofId(roofid);
    }
    if (chAddress !== null) {
      setCheckeAddress(chAddress);
    }
    if (latlng !== null) {
      setNewLatlng(latlng);
    }
  }, []);

  useEffect(() => {
    let newTypes;
    if (roofTypes.length > 0) {
      if (checkeAddress !== undefined && checkeAddress.Helning > 10) {
        newTypes = roofTypes.filter((e) => e.id !== 2 && e.id !== 9);
        let oneType = newTypes[0];
        newTypes[0] = newTypes[1];
        newTypes[1] = newTypes[2];
        newTypes[2] = oneType;
        setNewRoofTypes(newTypes);
        setCheckeAddress(checkeAddress);
      } else if (checkedAddress !== undefined && checkedAddress.Helning > 10) {
        newTypes = roofTypes.filter((e) => e.id !== 2 && e.id !== 9);
        let oneType = newTypes[0];
        newTypes[0] = newTypes[1];
        newTypes[1] = newTypes[2];
        newTypes[2] = oneType;
        setNewRoofTypes(newTypes);
        setCheckeAddress(checkedAddress);
      } else {
        newTypes = roofTypes.filter((e) => e.id === 2 || e.id === 9);
        setNewRoofTypes(newTypes);
      }
    }
  }, [roofTypes]);

  const sendRoofInfo = (id, name) => {
    dispatch(setOrderRoofType(name));
    dispatch(setShow("step2"));
    let newAdress = {
      ...checkeAddress,
      typetak: id,
      lat: newLatlng.lat,
      lng: newLatlng.lng,
      partnerId: partnerId
    };

    if (id !== 2 && id !== 9) {
      if (checkeAddress.Helning < 10) {
        let newAdress1 = {
          ...newAdress,
          produkttype: 69,
        };
        setCheckeAddress(newAdress1);
        dispatch(getRoofInfo(newAdress1));
        dispatch(setRoofInformation(newAdress1));
      } else if (checkeAddress.Helning >= 10) {
        let newAdress2 = { ...newAdress, produkttype: 67 };
        setCheckeAddress(newAdress2);
        dispatch(getRoofInfo(newAdress2));
        dispatch(setRoofInformation(newAdress2));
      }
    } else {
      let newAdress3 = {
        ...newAdress,
        produkttype: 68,
      };
      setCheckeAddress(newAdress3);
      dispatch(getRoofInfo(newAdress3));
      dispatch(setRoofInformation(newAdress3));
    }

    dispatch(setFinalAddress(newAdress));
  };

  return (
    <div className="roof-types-section">
      <div className="roof-types-header">
        <div>
          <button
            data-for="main"
            data-tip="Hvilket taktek ke har du?"
            onClick={() => {
              dispatch(setShow("step1"));
              dispatch(setRoofInfo(""));
              navigate("/search");
            }}
          >
            <Leftarrowicon />
          </button>
          <span>Hvilket taktekke har du?</span>
          <TooltipComponentBack />
        </div>
        <div>
          <span>Trinn 1 av 3</span>
        </div>

        <div>
          {roofName !== "null" ? (
            <button
              className="rigth-arrow"
              data-for="go-to-step2"
              data-tip="Gå til trinn 2"
              onClick={() => {
                let id = window.sessionStorage.getItem("roofID");
                sendRoofInfo(id, roofName);
              }}
            >
              <Leftarrowicon />
            </button>
          ) : null}
          <TooltipComponentForward />
          <button
            data-for="mains"
            data-tip="Avbryt"
            onClick={() => {
              navigate("/");
              dispatch(setShow("step1"));
              dispatch(setRoofInfo(""));
            }}
          >
            <Closeicon />
          </button>
          <span>Avbryt</span>
          <TooltipComponentClose />
        </div>
      </div>
      <div className="roof-types-container">
        {newRoofTypes !== undefined
          ? newRoofTypes.map((roof, index) => {
              return (
                <div
                  key={roof.id}
                  className={
                    "each-roof-type " + (roofId === roof.id ? "grey-roof" : "")
                  }
                >
                  <img
                    className={roofId === roof.id ? "img-grey" : ""}
                    src={roof.imgsrc}
                    alt={roof.name}
                    onClick={() => {
                      sendRoofInfo(roof.id, roof.name);
                      setRoofId(roof.id);
                      window.sessionStorage.setItem("roofID", roof.id);
                      window.sessionStorage.setItem("roofName", roof.name);
                    }}
                  />
                  <h3>{roof.name}</h3>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default RoofTypes;
