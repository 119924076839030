import "./RoofPrice.scss";
import { Leftarrowicon } from "../../components/icons/Leftarrowicon";
import { Closeicon } from "../../components/icons/Closeicon";
import { useDispatch, useSelector } from "react-redux";
import { setShow } from "../../store/Show/action";
import { useNavigate } from "react-router";
import { Arrowdownicon } from "../icons/Arrowdownicon";
import Solkarthvit from "../../assets/images/solkarthvit.png";
import RoofPanel from "../RoofPanel/RoofPanel";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useState, useEffect } from "react";
import { getRoofInfo, setRoofInfo } from "../../store/Roof/action";
import { TooltipComponentBack } from "../TooltipComponent/TooltipComponent";
import { TooltipComponentClose } from "../TooltipComponent/TooltipComponent";
import {
  setEnova,
  setOrderInfo,
  setOrderPanelCount,
  setOrderRoofInfo,
  setPanelType,
  setRoofInformation,
  setUserComment,
} from "../../store/Order/action";
import premiumPanel from "../../assets/images/premiumpanel.png";
import performancePanel from "../../assets/images/performancepanel.png";
import economyPanel from "../../assets/images/economypanel.png";
import { getRoofCalculation } from "../../api/getRoofCalculation";
import { Discounticon } from "../icons/Discounticon";

const RoofPrice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let roofInfo = useSelector((state) => state.Roof.roofInfo);
  let panelCount = useSelector((state) => state.Order.panelCount);
  let roofInformation = useSelector((state) => state.Order.roofInformation);
  let finalAddress = useSelector((state) => state.Address.finalAddress);
  let orderInfo = useSelector((state) => state.Order);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [type, setType] = useState("Premium - 410Wp");
  const [enovaSupport, setEnoveSupport] = useState(0);
  const [netPrice, setNetPrice] = useState(0);
  const [lcoe, setLcoe] = useState(0);
  const [finalRoof, setFinalRoof] = useState([]);
  const [comment, setComment] = useState("");
  const [showType405, setShowType405] = useState(false);
  const [showType430, setShowType430] = useState(false);
  const [showType510, setShowType510] = useState(false);
  const [discount, setDiscount] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [panelDiscount, setPanelDiscount] = useState(0);
  const [financingRequired, setFinancingRequired] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (Object.keys(roofInfo).length > 0 && !roofInfo.message) {
      let tempFinalRoof = [];
      let count;
      let discount = 0;

      for (const key in roofInfo.paneler) {
        let max = roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler;
        let found;
        if (panelCount === "") {
          found = roofInfo.paneler.find(
            (element) => element.pris * 1.25 >= 190000
          );
        } else {
          found = roofInfo.paneler.find(
            (element) => element.antallPaneler === panelCount
          );
        }

        if (found !== undefined) {
          count = found.antallPaneler;
          discount = found.discount;
        } else {
          count = roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler;
        }

        if (max < count) {
          dispatch(setOrderPanelCount(max));
        } else {
          dispatch(setOrderPanelCount(count));
        }
        if (roofInfo.paneler[key].antallPaneler === +panelCount) {
          tempFinalRoof.push(roofInfo.paneler[key]);
          setFinalRoof(tempFinalRoof);
        }
      }
      setPanelDiscount(discount * 1.25);
    }
  }, [roofInfo, panelCount]);

  useEffect(() => {
    if (Object.keys(roofInfo).length > 0 && !roofInfo.message) {
      let tempFinalRoof = [];
      for (const key in roofInfo.paneler) {
        if (roofInfo.paneler[key].antallPaneler === +panelCount) {
          tempFinalRoof.push(roofInfo.paneler[key]);
          setFinalRoof(tempFinalRoof);
        }
      }
    }
  }, [panelCount]);
  useEffect(() => {
    if (finalRoof.length > 0) {
      let result = getRoofCalculation(finalRoof[0]);
      setLcoe(result.lcoeC);
      setEnoveSupport(result.enova);
      setNetPrice(result.netP);
      dispatch(setEnova(result.enova));
    }
  }, [finalRoof]);

  const changePaenlType = (panelType) => {
    setShowType405(false);
    setShowType430(false);
    setShowType510(false);
    setShowDropdown(!showDropdown);

    if (
      roofInfo.forutsetninger.takType !== 2 &&
      roofInfo.forutsetninger.takType !== 9
    ) {
      if (panelType === "410Wp") {
        if (finalAddress.Helning < 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 69,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 69 }));
        } else if (finalAddress.Helning > 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 67,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 67 }));
        }
      } else if (panelType === "430Wp") {
        if (finalAddress.Helning < 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 72,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 72 }));
        } else if (finalAddress.Helning > 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 70,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 70 }));
        }
      } else if (panelType === "505Wp") {
        if (finalAddress.Helning < 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 74,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 74 }));
        } else if (finalAddress.Helning > 10) {
          finalAddress = {
            ...finalAddress,
            produkttype: 73,
          };
          dispatch(setRoofInformation({ ...roofInformation, produkttype: 73 }));
        }
      }
    } else {
      if (panelType === "410Wp") {
        finalAddress = {
          ...finalAddress,
          produkttype: 69,
        };
        dispatch(setRoofInformation({ ...roofInformation, produkttype: 69 }));
      } else if (panelType === "430Wp") {
        finalAddress = {
          ...finalAddress,
          produkttype: 72,
        };
        dispatch(setRoofInformation({ ...roofInformation, produkttype: 72 }));
      } else if (panelType === "505Wp") {
        finalAddress = {
          ...finalAddress,
          produkttype: 74,
        };
        dispatch(setRoofInformation({ ...roofInformation, produkttype: 74 }));
      }
    }

    dispatch(getRoofInfo(finalAddress));
  };
  const handleAskApply = async () => {
    if (discount) {
      finalRoof[0].voucherCode = discount;
      finalAddress = {
        ...finalAddress,
        voucherCode: discount,
        produkttype: roofInformation.produkttype,
      };
      dispatch(getRoofInfo(finalAddress));
      if (roofInfo.validVoucher) {
        dispatch(setPanelType(type));
        dispatch(
          setOrderRoofInfo({
            lcoe: lcoe,
            anProd: finalRoof[0].kwtAar,
          })
        );
        dispatch(setOrderInfo(finalRoof[0]));
        if (orderInfo.partnerId === 10024 || orderInfo.partnerId === 10004) {
          dispatch(
            setOrderInfo({
              ...finalRoof[0],
              financingRequired: financingRequired,
            })
          );
        } else {
          dispatch(setOrderInfo(finalRoof[0]));
        }
        dispatch(setUserComment(comment));
      }
    }
  };

  const handleAskQuote = async () => {
    dispatch(setShow("step3"));
    dispatch(setPanelType(type));
    dispatch(
      setOrderRoofInfo({
        lcoe: lcoe,
        anProd: finalRoof[0].kwtAar,
      })
    );
    dispatch(setOrderInfo(finalRoof[0]));
    if (orderInfo.partnerId === 10024 || orderInfo.partnerId === 10004) {
      dispatch(
        setOrderInfo({
          ...finalRoof[0],
          financingRequired: financingRequired,
          voucherCode: discount,
        })
      );
    } else {
      dispatch(setOrderInfo(finalRoof[0]));
    }
    dispatch(setUserComment(comment));
  };

  const showRabatt = () => {
    if (roofInfo.validVoucher) {
      return true;
    }
    return false;
  };
  return (
    <div className="roof-price-section">
      <div className="roof-price-header">
        <div>
          <button
            data-for="main"
            data-tip="Tilbake til taktype"
            onClick={() => {
              dispatch(setShow("step1"));
              dispatch(setRoofInfo(""));
              dispatch(setOrderPanelCount(""));
            }}
          >
            <Leftarrowicon />
          </button>
          <span>Tilbake til taktype</span>
          <TooltipComponentBack />
        </div>
        <div>
          <span>Trinn 2 av 3</span>
        </div>
        <div>
          <button
            data-for="mains"
            data-tip="Avbryt"
            onClick={() => {
              navigate("/");
              dispatch(setShow("step1"));
              dispatch(setRoofInfo(""));
            }}
          >
            <Closeicon />
          </button>
          <span>Avbryt</span>
          <TooltipComponentClose />
        </div>
      </div>
      {Object.keys(roofInfo).length > 0 &&
      finalRoof.length > 0 &&
      !roofInfo.message ? (
        <>
          <div className="roof-price-container">
            <div className="roof-price-check">
              <div className="roof-price-info">
                <div className="roof-price-list">
                  <div className="annual-production">
                    <p>Estimert årlig produksjon</p>
                    <p>
                      {Intl.NumberFormat("en-US")
                        .format(Math.ceil(finalRoof[0].kwtAar))
                        .replace(",", " ")}{" "}
                      kWh
                    </p>
                  </div>
                  <div className="nettopris">
                    <p>Pris etter ENOVA støtte</p>
                    <p>
                      {Intl.NumberFormat("en-US")
                        .format(Math.ceil(netPrice))
                        .replace(",", " ")}{" "}
                      NOK
                    </p>
                  </div>
                  <div className="size">
                    <p>Størrelse</p>
                    <p>{(finalRoof[0].kwp / 1000).toFixed(2)} kWp</p>
                  </div>
                  <div className="delivery-time">
                    <p>Leveringstid</p>
                    <p>4-8 uker</p>
                  </div>
                </div>
                <div className="dropdown">
                  <div
                    className="roof-price-dropdown"
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                      setShowType405(showType405 ? false : "");
                      setShowType430(showType430 ? false : "");
                      setShowType510(showType510 ? false : "");
                      setShowMore(showMore ? false : "");
                    }}
                  >
                    <div>
                      <p>Type solcellepanel</p>
                      <div>
                        <p>{type} </p>
                        {!showType405 ? (
                          <button
                            className="les-mer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowMore(!showMore);
                              setShowDropdown(!showDropdown);
                              setShowType405(showType405 ? false : "");
                              setShowType430(showType430 ? false : "");
                              setShowType510(showType510 ? false : "");
                            }}
                          >
                            {!showMore ? "Les mer" : "Lese mindre"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      <button>
                        <Arrowdownicon />
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${
                      showDropdown || showMore ? "expanded" : "collapsed"
                    } roof-price-dropdown-container`}
                    style={
                      showMore
                        ? { height: "380px", overflowY: "scroll" }
                        : { height: "", overflowY: "hidden" }
                    }
                  >
                    <div className="roof-price-365W">
                      {!showMore ? (
                        <div className="roof-price-365W-title">
                          Premium - 410Wp
                          {!showType405 ? (
                            <button
                              className="choose"
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("Premium - 410Wp");
                                changePaenlType("410Wp");
                              }}
                            >
                              Velg
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showMore && type === "Premium - 410Wp" ? (
                        <div
                          className="roof-price-365W-container"
                          style={
                            showMore
                              ? { display: "grid", height: "500px" }
                              : { display: "none", height: "" }
                          }
                        >
                          <div>
                            <img
                              src={premiumPanel}
                              alt="Solcellepanel 365W"
                              className="solcellepanel-type"
                            />
                          </div>
                          <div className="roof-price-dropdown-section">
                            <h1>Premium - 410Wp</h1>
                            <div className="badge">Farge svart</div>
                            <div className="roof-price-dropdown-list">
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>Helsvarte utførelse.</span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>410Wp</span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>
                                  12års produktgaranti/25års effekt garanti.
                                </span>
                              </div>
                            </div>
                            <div className="roof-price-dropdown-info">
                              Et godt valg for deg som ønsker et billigst mulig
                              anlegg. God effektutnyttelse av et fysisk mindre
                              panel. Panelet har integrerte bypass dioder for
                              maksimal effekt.
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="roof-price-395W">
                      {!showMore ? (
                        <div className="roof-price-395W-title">
                          Performance - 430Wp
                          {!showType430 ? (
                            <button
                              className="choose"
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("Performance - 430Wp");
                                changePaenlType("430Wp");
                              }}
                            >
                              Velg
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showMore && type === "Performance - 430Wp" ? (
                        <div
                          className="roof-price-395W-container"
                          style={
                            type === "Performance - 430Wp"
                              ? { display: "grid", height: "500px" }
                              : { display: "none", height: "" }
                          }
                        >
                          <div>
                            <img
                              src={performancePanel}
                              alt="Solcellepanel 395W"
                              className="solcellepanel-type"
                            />
                          </div>
                          <div className="roof-price-dropdown-section">
                            <h1>Performance - 430Wp</h1>
                            <div className="badge">Farge svart</div>
                            <div className="roof-price-dropdown-list">
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>Helsvart utførelse</span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>430Wp</span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>
                                  15års produkt garanti / 25års effektgaranti
                                </span>
                              </div>
                            </div>
                            <div className="roof-price-dropdown-info">
                              Vår bestselger! Eksepsjonell verdi for pengene fra
                              en av bransjens ledende aktører. Panelene har
                              bypassdioder og er svært effektive i skygge.
                              Panelene inneholder ingen fluor og har et svært
                              lavt innhold av ledd.
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="roof-price-510W">
                      {!showMore ? (
                        <div className="roof-price-510W-title">
                          Economy - 505Wp
                          {!showType430 ? (
                            <button
                              className="choose"
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("Economy - 505Wp");
                                changePaenlType("505Wp");
                              }}
                            >
                              Velg
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showMore && type === "Economy - 505Wp" ? (
                        <div
                          className="roof-price-510W-container"
                          style={
                            type === "Economy - 505Wp"
                              ? { display: "grid", height: "500px" }
                              : { display: "none", height: "" }
                          }
                        >
                          <div>
                            <img
                              src={economyPanel}
                              alt="Solcellepanel 505W"
                              className="solcellepanel-type"
                            />
                          </div>
                          <div className="roof-price-dropdown-section">
                            <h1>Economy - 505Wp</h1>
                            <div className="badge">Farge svart</div>
                            <div className="roof-price-dropdown-list">
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>
                                  Svarte celler og ramme, Hvit backsheet.
                                </span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>505Wp</span>
                              </div>
                              <div>
                                <img src={Solkarthvit} alt="Solkart" />
                                <span>
                                  12års produktgaranti/ 25 års effektgaranti
                                </span>
                              </div>
                            </div>
                            <div className="roof-price-dropdown-info">
                              Panelet for deg som ønsker mest mulig produksjon
                              eller ønsker mest mulig produksjon ut av en mindre
                              takflate.
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="roof-price-table">
                  <div>
                    <h4>Pris for ditt anlegg</h4>
                  </div>
                  <div>
                    <span>Totalpris</span>
                    <span>=</span>

                    <span>
                      {Intl.NumberFormat("en-US")
                        .format(Math.ceil(finalRoof[0].pris * 1.25))
                        .replace(",", " ")}
                    </span>
                  </div>
                  <div>
                    <span>Støtte fra ENOVA</span>
                    <span>-</span>
                    <span>
                      {Intl.NumberFormat("en-US")
                        .format(Math.ceil(enovaSupport))
                        .replace(",", " ")}
                    </span>
                  </div>
                  {showRabatt() ? (
                    <div>
                      <span>Rabatt</span>
                      <span>-</span>
                      <span>
                        {Intl.NumberFormat("en-US")
                          .format(Math.ceil(panelDiscount))
                          .replace(",", " ")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <span>Pris etter ENOVA støtte</span>
                    <span>=</span>
                    <span>
                      {Intl.NumberFormat("en-US")
                        .format(Math.ceil(netPrice - panelDiscount))
                        .replace(",", " ")}
                    </span>
                  </div>
                  <div>
                    <span>Priser er i NOK og ink MVA</span>
                    <span></span>
                    <span></span>
                  </div>
                  {orderInfo.partnerId === 10024 ||
                  orderInfo.partnerId === 10004 ? (
                    <div className="banking-container">
                      <div className="financing-card">
                        <span>
                          <p>Ønsker du å få finansiering?</p>
                          <div>
                            <button
                              className="les-mer"
                              onClick={() => setShowPopup(true)}
                            >
                              Les Mer
                            </button>
                          </div>
                        </span>
                        <span className="toggle-container">
                          <span className="nei-ja">Nei</span>
                          <span>
                            <label className="toggle">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setFinancingRequired(e.target.checked)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          </span>
                          <span className="nei-ja">Ja</span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="kommentar">
                    <span>Kommentar</span>
                    <span>
                      <textarea
                        name=""
                        id=""
                        cols=""
                        rows="5"
                        placeholder=" Her kan du skrive om du har noen ønske"
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      ></textarea>
                    </span>
                  </div>
                </div>
                {orderInfo.partnerId === 10024 ||
                orderInfo.partnerId === 10004 ? (
                  <div className="discount-container">
                    <span>
                      <span className="input-icons">
                        <input
                          type="text"
                          placeholder="Skriv inn rabattkode"
                          value={discount}
                          disabled={roofInfo.validVoucher === true}
                          onChange={(e) => {
                            setDiscount(e.target.value);
                            setIsTyping(e.target.value.length > 0);
                          }}
                        />
                        <Discounticon />
                      </span>
                      {roofInfo.validVoucher !== undefined &&
                        roofInfo.validVoucher === false && (
                          <p>
                            Ugyldig rabattkode. Venligst skriv inn en annen kode
                          </p>
                        )}
                    </span>
                    <button
                      className="ask-for-apply"
                      onClick={handleAskApply}
                      disabled={roofInfo.validVoucher === true}
                      style={
                        roofInfo.validVoucher === true
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                    >
                      Søk rabattkoden
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <button className="ask-for-quote" onClick={handleAskQuote}>
                  Be om et tilbud
                </button>
              </div>
            </div>
            <RoofPanel data={{ lcoe: lcoe, anProd: finalRoof[0].kwtAar }} />
          </div>
          <div className="price-information">
            <p>
              * Solkart tar ikke hensyn til snø og lokale skyggeforhold (fra
              trær, andre bygninger ol.) når produksjonsestimatet genereres.
              Tallmaterialet bør derfor ikke være ditt eneste grunnlag for å
              gjøre en solcelleinvestering. Prisen inkluderer tilskudd til
              strømproduksjon fra Enova. Alle priser inkluderer mva.
            </p>
            <br />
            <p>
              Estimatet over hva anlegget kan produsere er basert på
              tilgjengelig informasjon. Det kan forekomme lokale variasjoner,
              som høye trær, nye bygg og andre topografiske forhold som påvirker
              produksjonen. Dersom det er slike forhold i nærheten av boligen,
              er dette forhold som kan medføre at produksjonen blir vesentlig
              annerledes enn estimatet viser
            </p>
            <br />
            Klikk på et av alternativene for ytterligere spesifikasjoner.{" "}
          </div>
        </>
      ) : roofInfo.message ? (
        <div className="error-message">
          <ErrorMessage />
        </div>
      ) : (
        <div>
          <LoadingSpinner />
        </div>
      )}
      {showPopup && (
        <div className="banking-menu-popup">
          <div className="popup-content">
            <h1>
              Nyhet!
              <br />
              Solcelle lån via Svea finans
            </h1>
            <p className="first-p">
              Vi tilbyr nå rentefri nedbetaling opp til 5år via vår
              finansieringspartner Svea.
            </p>
            <p className="second-p">Maksgrense 200 000,-</p>
            <hr />
            <h3>Så enkel får du et tilbud om finansiering:</h3>
            <ol>
              <li> Huk av "Ja" på finansieringen.</li>
              <li>
                Du får et priseksempel på finansiering sammen med tilbudet du
                forsperr
              </li>
              <li>Ved aksept vil du motta tilbud på finansiering.</li>
            </ol>
            <hr />
            <h3>Eksempelordre på 100 000,- finansiert over 5 år (60 mnd):</h3>
            <div className="sikkerhet-content">
              <div>
                <h3>Lån uten sikkerhet</h3>
                <ul>
                  <li>Totalpris: 105 499,-</li>
                  <li>Etableringsgebyr: 999,-</li>
                  <li>Terminer (antall mnd): 60</li>
                  <li>Effektiv rente: 2,17%</li>
                  <li>Termingebyr: 75,-</li>
                  <li>Månedskostnad: 1 758,-</li>
                  <li>Årlig rente: 0%</li>
                </ul>
              </div>
            </div>
            <div className="note-content">
              <p>
                (Obs, månedskostnad er oppgitt som snitt, men hele
                etableringsgebyret påløper første faktura - det vil si den
                første blir høyere, alle de neste noe lavere, enn oppgitt
                månedskostnad)
              </p>
              <p>
                Utfyllende vilkår, samt de generelle vilkår (som gjøres
                gjeldende ved manglende eller delvis betaling av en termin)
                finnes her:{" "}
                <a href="https://www.svea.com/kontovilkar" target="_blank">
                  https://www.svea.com/kontovilkar
                </a>
              </p>
            </div>
            <div className="button-container">
              <button onClick={() => setShowPopup(false)}>Lukk</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoofPrice;
